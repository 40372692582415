export default {
  data() {
    return {
      index: null,
      queryYear: null,
      Url: '',
    };
  },
  created() {
    const { query } = this.$route;

    const {
      year,
    } = query;

    if (year !== undefined) {
      console.log('query year is not undefined');
      this.queryYear = year;
    }
    this.$root.$on('copyUrl', this.onCopyUrl);
  },
  mounted() {
    this.clearQueryParams();
  },
  beforeDestroy() {
    // Unsubscribe from the event when the component is destroyed
    this.$root.$off('copyUrl', this.onCopyUrl);
  },
  methods: {
    clearQueryParams() {
      // Remove the specified parameters from the URL
      const urlWithoutParams = new URL(window.location.href);
      urlWithoutParams.searchParams.delete('indicator');
      urlWithoutParams.searchParams.delete('datasource');
      urlWithoutParams.searchParams.delete('location');
      urlWithoutParams.searchParams.delete('year');

      window.history.pushState({}, '', urlWithoutParams.toString());
    },

    // Method to update route parameters
    updateYear() {
      if (this.queryYear == null) {
        const {
          year,
        } = this.payload;
        const params = {
          year,
          indicator: this.payload.indicator?.id,
          datasource: this.payload.datasource?.id,
          location: this.payload.location?.id,
        };
        const queryString = Object.keys(params)
          .filter((key) => params[key] !== undefined)
          .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
          .join('&');

        const newUrl = `${window.location.pathname}?${queryString}`;
        this.Url = newUrl;
      } else this.updateParams();
    },
    updateParams() {
      const {
        indicator, datasource, location, year,
      } = this.payload;

      const params = {
        indicator: indicator?.id,
        datasource: datasource?.id,
        location: location?.id,
        year,
      };

      const queryString = Object.keys(params)
        .filter((key) => params[key] !== undefined)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        .join('&');

      const newUrl = `${window.location.pathname}?${queryString}`;
      if (this.index === 0 || this.index === 1) {
        // Use pushState to update the URL
        this.Url = newUrl;
      }
    },
    initializeFromParams() {
      const { query } = this.$route;
      if (query) {
        const {
          indicator, datasource, location,
        } = query;

        if (indicator !== undefined) {
          this.payload.indicator = { id: Number(indicator) };
        }

        if (datasource !== undefined) {
          this.payload.datasource = { id: Number(datasource) };
        }

        if (location !== undefined) {
          this.payload.location = { id: Number(location) };
        }
      }
    },

    onCopyUrl() {
      console.log('Query year URL copied to clipboard:', window.location.origin + this.Url);
    },
  },
  watch: {
    // Watch for changes in payload properties and update route parameters
    'payload.indicator': 'updateParams',
    'payload.datasource': 'updateParams',
    'payload.location': 'updateParams',
    'payload.year': 'updateYear',
    controlIndex: {
      async handler(newValue) {
        this.index = newValue;
        if (newValue !== 0 && newValue !== 1) { this.removeQuery(); } else this.updateParams();
      },
      immediate: true,
      deep: true,
    },
  },
};
