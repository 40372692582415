import { render, staticRenderFns } from "./prompt.vue?vue&type=template&id=51111063&scoped=true"
import script from "./prompt.vue?vue&type=script&lang=js"
export * from "./prompt.vue?vue&type=script&lang=js"
import style0 from "./prompt.vue?vue&type=style&index=0&id=51111063&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51111063",
  null
  
)

export default component.exports